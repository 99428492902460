var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('page-title',{attrs:{"title":"Registered Campaign"}},[_c('template',{slot:"action"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.addNew()}}},[_vm._v("Add")])],1)],2),_c('v-row',[_c('v-col',[_c('v-card',[_c('v-toolbar',{attrs:{"dense":"","elevation":"0"}},[_c('v-toolbar-title',[_vm._v("List Data")]),_c('v-spacer'),_c('v-text-field',{staticStyle:{"max-width":"300px"},attrs:{"dense":"","autofocus":"","outlined":"","rounded":"","placeholder":"Search","append-icon":"mdi-magnify","hide-details":"","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{attrs:{"icon":"","title":"Refresh"},on:{"click":function($event){return _vm.getData(true)}}},[_c('v-icon',[_vm._v("mdi-sync")])],1)],1),_c('v-data-table',{staticClass:"mb-3 my-0",attrs:{"loading":_vm.datatable.loading,"loading-text":_vm.datatable.loadingText,"headers":_vm.datatable.columns,"items":_vm.datatable.data,"search":_vm.search,"dense":""},scopedSlots:_vm._u([{key:"item.no",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(item)?_c('span',[_vm._v(_vm._s(index+1))]):_vm._e()]}},{key:"item.sync_status",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-switch',{staticClass:"my-0 py-0",staticStyle:{"height":"24px"},attrs:{"label":(item.sync_status==1) ? 'Yes':'No',"value":item.sync_status,"dense":""},on:{"change":function($event){return _vm.setStatus(item, index)}},model:{value:(item.sync_status),callback:function ($$v) {_vm.$set(item, "sync_status", $$v)},expression:"item.sync_status"}})]}},{key:"item.opsi",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","small":"","color":"red"},on:{"click":function($event){return _vm.del(item.campaign_no_)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)})],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"300"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Add Campaign")]),_c('v-card-text',[_c('v-text-field',{ref:"refNewCampaignNo",attrs:{"placeholder":"Campaign No","label":"Campaign No"},model:{value:(_vm.new_campaign_no_),callback:function ($$v) {_vm.new_campaign_no_=$$v},expression:"new_campaign_no_"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.save}},[_vm._v("Save")]),_c('v-btn',{on:{"click":function($event){_vm.dialog=!_vm.dialog}}},[_vm._v("Close")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }