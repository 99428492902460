<template>
  <div>
    <v-container>
      <page-title title="Registered Campaign">
        <template slot="action">
          <v-btn color="primary" @click.stop="addNew()">Add</v-btn>
        </template>
      </page-title>
      <v-row>
        <v-col>
          <v-card>
            <v-toolbar dense elevation="0">
              <v-toolbar-title>List Data</v-toolbar-title>

              <v-spacer></v-spacer>

              <v-text-field dense autofocus outlined rounded placeholder="Search" v-model="search" append-icon="mdi-magnify" style="max-width: 300px" hide-details single-line></v-text-field>

              <v-btn icon @click="getData(true)" title="Refresh">
                <v-icon>mdi-sync</v-icon>
              </v-btn>
            </v-toolbar>
            <v-data-table :loading="datatable.loading" :loading-text="datatable.loadingText" :headers="datatable.columns" :items="datatable.data" :search="search" dense class="mb-3 my-0">
              <template v-slot:[`item.no`]="{ item, index }">
                <span v-if="item">{{ index+1 }}</span>
              </template>
              <template v-slot:[`item.sync_status`]="{ item, index }">
                <v-switch :label="(item.sync_status==1) ? 'Yes':'No'" :value="item.sync_status" v-model="item.sync_status" @change="setStatus(item, index)" class="my-0 py-0" style="height:24px" dense></v-switch>
              </template>
              <template v-slot:[`item.opsi`]="{ item }">
                <!-- <a href="javascript:;" @click="getDetail(item.no_)">{{ item.no_ }}</a> -->
                <!-- <v-switch v-model="item.sync_status" @change="setStatus(index)" class="my-0 py-0" style="height:24px" dense></v-switch> -->
                <v-btn icon small color="red" class="mr-2" @click="del(item.campaign_no_)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="dialog" max-width="300">
      <v-card>
        <v-card-title>Add Campaign</v-card-title>
        <v-card-text>
          <v-text-field v-model="new_campaign_no_" ref="refNewCampaignNo" placeholder="Campaign No" label="Campaign No"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="save" color="primary">Save</v-btn>
          <v-btn @click="dialog=!dialog" >Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      search: "",
      new_campaign_no_: "",
      datatable: {
        loading: false,
        loadingText: "Loading... Please wait...",
        columns: [
          { text: "No.", value: "no" },
          { text: "Camapign No.", value: "campaign_no_" },
          { text: "Status", value: "sync_status" },
          { text: "Opsi", value: "opsi" },
        ],
        data: [],
      },
    };
  },

  methods: {
    getData(refresh) {
      this.datatable.loading = true;

      if(refresh) this.AxiosStorageRemove("GET", "registeredcampaign/data");
      
      var config = {
        params: {},
        cacheConfig: true,
      };
      this.$axios
        .get("registeredcampaign/data", config)
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.datatable.data = resData.data;
          } else {
            this.showAlert(resData.status, resData.message);
          }
          this.datatable.loading = false;
        })
        .catch((error) => {
          if (error.response) {
            this.$awn.alert("Internal Server Error");
            console.log(error.response.headers);
          } else {
            console.log("Error", error.message);
          }
          this.datatable.loading = false;
        });
    },
    setStatus(item, i) {
      // this.file_src = this.$refs.reffileupload.files[0];

      // var item = this.datatable.data[i];

      var campaign_no_ = item.campaign_no_;
      var s = item.sync_status;

      this.loading = true

      let formData = new FormData();
      formData.append("campaign_no_", campaign_no_);

      var uri = "registeredcampaign/set-active";

      if(s === true){
        uri = "registeredcampaign/set-active";
      }else{
        uri = "registeredcampaign/set-inactive";
      }

      this.$axios
        .post(uri, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.loading = false
          var resData = res.data;
          if (resData.status) {
            
            this.datatable.data[i] = resData.data

            this.showAlert(resData.status, resData.message);
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          this.loading = false
          this.axiosErrorHandler(error);
        });
    },
    addNew(){
      this.new_campaign_no_ = ""
      this.dialog = !this.dialog 
      setTimeout(() => {
          this.$refs.refNewCampaignNo.focus()
      }, 200)
    },
    save() {
      this.showLoadingOverlay(true);
      var campaign_no_ = this.new_campaign_no_;

      this.loading = true

      let formData = new FormData();
      formData.append("campaign_no_", campaign_no_);

      var uri = "registeredcampaign/save";


      this.$axios
        .post(uri, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.showLoadingOverlay(false);
          var resData = res.data;
          if (resData.status) {
            this.getData(true)
            this.dialog = false

            this.showAlert(resData.status, resData.message);
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });

    },
    del(campaign_no_) {

      if(!confirm("Delete?")) return;


      this.showLoadingOverlay(true);
      this.loading = true

      let formData = new FormData();
      formData.append("campaign_no_", campaign_no_);

      var uri = "registeredcampaign/delete";


      this.$axios
        .post(uri, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.showLoadingOverlay(false);
          var resData = res.data;
          if (resData.status) {
            this.getData(true)
            this.dialog = false

            this.showAlert(resData.status, resData.message);
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },
  },

  mounted() {
    this.requiredLogin();
    this.modulePermission('registeredcampaign', 'view', true)
    this.getData();
  },
};
</script>